<template>
    <div id="main" class="kefan">
      <div class="selection">
        <div class="block">
          <div class="left">
            <el-select v-model="value" @change="changevalue" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="right">
            <el-date-picker
              v-model="value1"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="timestamp"
              @change="changedate"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="block">
          <div class="left">
            <el-input v-model="input" placeholder="微信订单号模糊查询"></el-input>
          </div>
          <div class="right">
            <el-button type="primary" plain @click="search">搜索</el-button>
          </div>
          <div class="left">
            <el-button type="success" plain @click="dataexport">导出</el-button>
          </div>
        </div>
      </div>
      <div class="tag">
        <el-tag type="info">当日收款：{{ sum }}元</el-tag>
      </div>
      <div class="data">
        <el-table :data="tableData" stripe style="width: 100%">
          <el-table-column prop="oid" label="内部订单号" width="180">
          </el-table-column>
          <el-table-column prop="wxid" label="微信订单号" width="240">
          </el-table-column>
          <el-table-column prop="name" label="姓名" width="180">
          </el-table-column>
          <el-table-column prop="type" label="类目" width="180">
          </el-table-column>
          <el-table-column prop="amountmoney" label="应付金额" width="140">
          </el-table-column>
          <el-table-column prop="paymoney" label="实付金额" width="140">
          </el-table-column>
          <el-table-column prop="paytime" label="支付时间"> </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            width="150">
            <template slot-scope="scope">
                <el-button @click="handlescope(scope)" type="text" size="small">明细</el-button>
            </template>
        </el-table-column>
        </el-table>
      </div>
      <div class="page">
        <span class="demonstration"></span>
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="pagesize"
          layout="total, prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
      <el-dialog title="明细" :visible.sync="dialogTableVisible">
        <el-table :data="list" style="width: 100%">
          <el-table-column :prop="item.prop" :label="item.label" v-for="item in label" :key="item.prop"></el-table-column>
        </el-table>
      </el-dialog>
    </div>

    
  </template>
  <script>
  import axios from "axios";
  export default {
    data() {
      return {
        tableData: [],
        list: [],
        label:[],
        pagesize: 10,
        currentPage: 1,
        sum: 0,
        input: "",
        total: 0,
        page: 1,
        value1: "",
        options: [],
        value:"",
        dialogTableVisible: false,
      };
    },
    mounted() {
      this.init();
      
    },
    methods: {
      // 导出当前查询的数据
      dataexport() {
        if (this.value1 !== null) {
          axios({
            method: "post",
            url: this.$host + this.$api.cbxexcel,
            headers: {
              'Content-Type':'application/json',
            },
            responseType:'blob',
            data: {
              type: this.value,
              timelist: this.value1,
              value: this.input,
            },
          }).then((res)=>{
            let url=window.URL.createObjectURL(new Blob([res.data]));
            let link=document.createElement('a')
            link.download='账单统计.xlsx'
            link.href=url
            link.click()
          });
        }else{
          this.$notify({
            title: "查询失败",
            message: "请先选择日期范围",
            type: "error",
          });
        }
      },
      changevalue() {
        this.gettable();
      },
      changedate() {
        console.log(this.value1);
      },
      init() {
        // 初始化日期对象，默认为今天的0点
        var date = new Date();
        date.setHours(0, 0, 0, 0);
        const timestamp = date.getTime();
        this.value1 = [timestamp, timestamp];
        // 获取options
        axios({
            method:"post",
            url:this.$zclocal+this.$api.life,
            data:{
                do:"orderType"
            },
        }).then((res)=>{
            this.options = res.data.data
            this.value = this.options[0].value
        }).then(()=>{
            this.gettable();
        })
      },
      search(){
        this.currentPage =1;
        this.gettable();
      },
      gettable() {
        axios({
          method: "post",
          url: this.$host + this.$api.cbxbill,
          data: {
            page: this.currentPage,
            pagesize: this.pagesize,
            type: this.value,
            timelist: this.value1,
            value: this.input,
          },
        }).then((res) => {
          this.tableData = res.data.data;
          this.total = res.data.total;
          this.sum = res.data.sum;
        });
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.currentPage = val;
        this.gettable();
      },
      handlescope(val){
        let oid = val.row.oid;
        let data = {
            do:"orderDetail",
            oid:oid
        }
       this.$post(this.$zclocal+this.$api.life,data).then((res)=>{
           if(res.data.code == 200){
               this.label = res.data.data.label;
               this.list = res.data.data.list;
                this.dialogTableVisible = true;
            }else{
                this.$notify({
                    title: "查询失败",
                    message: res.data.msg,
                    type: "error",
                });
            }
        })
      },


    },
  };
  </script>
  <style scoped>
  .page {
    display: flex;
    justify-content: right;
    margin-top: 15px;
  }
  .tag {
    display: flex;
    justify-content: right;
    margin: 10px 10px;
  }
  .block {
    display: flex;
    align-items: center;
    justify-content: right;
  }
  .selection {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .left{
    margin:0 20px;
  }
  </style>
  